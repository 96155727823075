import {
    AppBar,
    Button, CssBaseline,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Typography,
    useTheme
} from '@mui/material';
import {useEffect, useState} from 'react';
import Logo from './logo';
import DrawerComponent from './drawer';
import {useAuth} from "oidc-react";
import {useLocation, useNavigate} from "react-router-dom";
import {Workplace, Configuration, WorkplaceApi} from "../generated/workplace";
import {JobApi} from "../generated/job";
import { ChevronLeft, ChevronRight, Home } from '@mui/icons-material';
import ConnectionStatus from './connectionStatus';
import { useConfigStore, useImagesStore, useSettingsStore, useStationStore, useSuperJobStore } from '../store/store';
import PowerOffButton from './PowerOffButton';
import { useTranslation } from 'react-i18next';
import CameraComponent from './camera/camera';


const Topbar = () => {
    const auth = useAuth();
    const theme = useTheme();

    const { t } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();

    
    const stationStore = useStationStore(state => state.station);
    const setStationStore = useStationStore(state => state.setStation);

    const isHomePage = location.pathname === '/';

    const isInstructionsPage = location.pathname === '/instructions';

    const isAccountPage = location.pathname === '/account';

    const isCustomerDataPage = location.pathname === '/customerdata';

    const isFulfillerDataPage = location.pathname === '/fulfillerdata';

    const isWorkplacesPage = location.pathname === '/workplaces';


    const globalJobId = useSettingsStore(state => state.jobId);
    const globalAlignement = useSettingsStore(state => state.alignmentType);
    const globalIsMultiPhaseCalibration = useSettingsStore(state => state.isMultiPhaseCalibration);
    const globalCalibration = useSettingsStore(state => state.calibration);


    const setGlobalAlignmentType = useSettingsStore(state => state.setAlignmentType);
    const setGlobalJobId = useSettingsStore(state => state.setJobId);

    const resetSuperJob = useSuperJobStore(state => state.resetSuperJob);

    const hasLicense = useConfigStore(state => state.hasLicense);

    const gotoCorrectPage = () => {

        if(location.pathname === "/einstellwerte"){

            let einstellwert = null;
            try {
                const json = localStorage.getItem("einstellwert");
                if(json){
                    einstellwert = JSON.parse(json);

                }
            } catch (error) {
                
            }

            let messTyp = einstellwert?.messTypId;
            switch (messTyp){
                case 2:
                    navigate("/fahrzeugfront")
                    setGlobalAlignmentType("Fahrzeugfront");
                    break
                case 3:
                    navigate("/radnabenmitte")
                    setGlobalAlignmentType("Radnabenmitte");
                    break
                case 4:
                    navigate("/frontkamera")
                    setGlobalAlignmentType("Frontkamera");
                    break
                default:
                    alert("Kein Messtyp ausgewählt")
            }

        }
        
        
        else if (location.pathname === "/fahrzeugfront" || location.pathname === "/radnabenmitte" || location.pathname === "/frontkamera") {
            if(globalCalibration.toLowerCase().includes("distanzregelung")){
                navigate("/customerdata");
            }else {
               
                if(globalIsMultiPhaseCalibration === true){
                    
                    if(globalAlignement === "Radnabenmitte" && localStorage.getItem("Phase") === "Phase 1"){
                        
                        //navigate("/radnabenmitte")
                        navigate("/target")
                    }
                    else if(globalAlignement === "Radnabenmitte" && localStorage.getItem("Phase") === "Phase 2"){
                        navigate("/target");
                    }
                    else if(globalAlignement === "Fahrzeugfront" && localStorage.getItem("Phase") === "Phase 1"){
                        navigate("/target")
                    }
                    else if(globalAlignement === "Fahrzeugfront" && localStorage.getItem("Phase") === "Phase 2"){
                        navigate("/target")
                    }
                    else if(globalAlignement === "Frontkamera" && localStorage.getItem("Phase") === "Phase 1"){
                        navigate("/target")
                    }
                    else if(globalAlignement === "Frontkamera" && localStorage.getItem("Phase") === "Phase 2"){
                        navigate("/target")
                    }
                    else {
                        navigate("/customerdata");
                    }
                    
                }else{
                    //localStorage.setItem("Phase", "Phase 1");
                    navigate("/target")
                }
                
            }
          
        }else if(location.pathname === "/target" && localStorage.getItem("Phase") === "Phase 1" && globalIsMultiPhaseCalibration === true){
            if(globalIsMultiPhaseCalibration === true){
                if(globalAlignement === "Radnabenmitte" && localStorage.getItem("Phase") === "Phase 1"){
                    navigate("/radnabenmitte")
                    localStorage.setItem("Phase", "Phase 2");
                }
                else if(globalAlignement === "Fahrzeugfront" && localStorage.getItem("Phase") === "Phase 1"){
                    navigate("/fahrzeugfront")
                    localStorage.setItem("Phase", "Phase 2");
                }
                else if(globalAlignement === "Frontkamera" && localStorage.getItem("Phase") === "Phase 1"){
                    navigate("/frontkamera")
                    localStorage.setItem("Phase", "Phase 2");
                }
                else{
                    navigate("/customerdata");
                }
            }
        }
        
        else {
            navigate("/customerdata");
        }

    }

    const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

    const handleLogoutClick = () => {
        setOpenLogoutDialog(true);
    };
    
    const handleCloseDialog = () => {
        setOpenLogoutDialog(false);
    };
    
    const handleConfirmLogout = () => {
        closeJobs();
        setOpenLogoutDialog(false);
    };

   
    const goHome = () => {
        if (globalJobId && globalJobId !== -1)
        {
            let jobApi = new JobApi();
            jobApi.changeState({ "id": globalJobId, "state": "Aborted"})
        }
        
        setGlobalJobId(-1);

        // SuperJob abloeschen
        resetSuperJob();

        // tmp Fotos aus Storage entfernen
        resetImages();

        navigate("/")

    }

    function handleBack(){
        navigate(-1);
      }

    function closeJobs(){
        // console.log(station);
        console.log(stationStore.id);
        let jobApi = new JobApi();
        jobApi.closeJobs({"device":stationStore.id ?? ""})
    }

    // const [_, setStation] = useState("");

    const [stations, setStations] = useState<Workplace[]>([]);
    
    const selectStation = (event: SelectChangeEvent) => {
        // setStation(event.target.value);
        console.log("selectStation", event.target.value);
        for (const stat of stations) {
            if(stat.id === event.target.value ){
                setStationStore(stat);
            }
        }
    }

    const imagesString = useImagesStore(state => state.imagesString);
    const setImagesString = useImagesStore(state => state.setImagesString);

    const saveImageToJob = (base64Image:string) => {
        if(globalJobId && globalJobId !== -1){
            if(base64Image){
                // let jobApi = new JobApi();
                // jobApi.postImage({ "id": globalJobId, postImageRequest:{"image": base64Image}});

                // const imagesString = localStorage.getItem("images");
                
                let images:{[key: string]: string[]};

                if(imagesString == null || imagesString.length === 0){
                    images = {
                        [globalJobId]: []
                    };

                }
                else{
                    images = JSON.parse(imagesString);
                }

                if(!images[globalJobId]){
                    images[globalJobId] = [];
                }

                if(images[globalJobId]){
                    images[globalJobId].push(base64Image)
                }

                // localStorage.setItem("images", JSON.stringify(images));
                setImagesString(JSON.stringify(images));

            }
        }
    }

    const resetImages = () => {
        // localStorage.setItem("images", JSON.stringify({}));
        setImagesString("");
    }
    
    

    const access_token = auth.userData?.access_token;
    const savedStation = stationStore;

    useEffect(() => {
        console.log("access_token changed");
        
    }, [access_token]);

    useEffect(() => {
        console.log("savedStation changed", savedStation);
    }, [savedStation]);

    useEffect(() => {
        
        if(access_token){
            
            let worklacApi = new WorkplaceApi(new Configuration({'accessToken': access_token!}));
    
            worklacApi.getWorkplaces().then((data) => {
                let stations: Workplace[] = [];
                data.forEach((item) => {
                    stations.push(item);
                    console.log(item);
                });
                setStations(stations);
            });
    
            // if (savedStation != null && savedStation.id != null) {
            //     // setStation(savedStation.id.toLowerCase());
            //     setStationStore(savedStation);
            // }

        }
    }, [access_token]);

    useEffect(() => {
        console.log("stations", stations);

        stations.forEach((item : any) => {
            // setStation(item.id?.toString());
            setStationStore(item);
        })

    }, [setStationStore, stations])

    // const ITEM_HEIGHT = 48;
    // const ITEM_PADDING_TOP = 8;
    // const MenuProps = {
    //     PaperProps: {
    //         style: {
    //             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    //             width: 250,
    //         },
    //     },
    // };

    let buttonType:any = "outlined";



    console.log("topbar render");
    console.log(location.pathname);
    console.log(window.location.origin+'/instructions');
    return (
        <AppBar position="sticky" color='customHeader'>
            <CssBaseline></CssBaseline>
            <Grid display={"flex"} flexDirection={"row"}  style={{height:"80px"}}>

                <Grid container direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={2} paddingRight={2}>
                    <Grid item 
                        xs={1} 
                        // sm={1} 
                        lg={1}
                    >
                        {!isInstructionsPage && (
                            <DrawerComponent />
                        )}
                    </Grid>
                    <Grid item 
                        xs={1.5} 
                        // sm={1} 
                        lg={2} 
                    >
                    {!isInstructionsPage ? (
                        !isHomePage ? (
                            <Button color='customHeaderButton' variant={buttonType} onClick={goHome} style={{border: '2px solid', height:'5vh', minWidth:"0px"}} fullWidth>
                                <Typography noWrap sx={{display: { xs: 'none', md: 'block' }}}>
                                    {t("home")}
                                </Typography>
                                <Home sx={{display: { xs: 'block', md: 'none' }}} />
                            </Button>
                    ) : (
                            <Button color='customHeaderButton' variant={buttonType} onClick={handleLogoutClick} style={{height:'5vh', minWidth:"0px"}} fullWidth >
                                <Typography noWrap>
                                    {t("resetTarget")}
                                </Typography>
                            </Button>
                    )
                    ) : null}
                    </Grid>
                    <Grid item 
                        xs={1.5} 
                        // sm={1} 
                        lg={2} 
                    >
                        {!isInstructionsPage ? (
                            !isHomePage && (
                                <Button color='customHeaderButton' variant={buttonType} onClick={handleBack} style={{border: '2px solid', height:'5vh', minWidth:"0px"}} fullWidth>
                                    <Typography noWrap sx={{display: { xs: 'none', md: 'block' }}}>
                                        {t("back")}
                                    </Typography>
                                    <ChevronLeft sx={{display: { xs: 'block', md: 'none' }}} />
                                </Button>
                                )
                        ):null}
                    </Grid>
                    <Grid item 
                        xs={4} 
                        // sm={3} 
                        lg={2}
                        style={{
                            height:"100%",
                            display: "flex",
                            justifyItems: "center",
                            alignItems: "center"
                        }}
                    >
                        <Logo></Logo>
                    </Grid>
                    <Grid item 
                        xs={2} 
                        // sm={3} 
                        lg={3}
                    >
                        {!isInstructionsPage ? (
                            !isWorkplacesPage ? (
                                !isAccountPage && (
                                    <FormControl 
                                        sx={{ 
                                            m:1, 
                                            color: theme.palette.customHeaderButton.main
                                            // border: '2px solid #ccc'
                                        }} 
                                        fullWidth
                                    >
                                        <InputLabel 
                                            sx={{
                                                color: theme.palette.customHeaderButton.main
                                            }} id="station-label"
                                        >
                                            {t("station")}
                                        </InputLabel>
                                        <Select
                                            sx={{
                                                height: "5vh",
                                                color: theme.palette.customHeaderButton.main,
                                                "& fieldset": {
                                                    border: '2px solid',
                                                    borderColor: theme.palette.customHeaderButton.main

                                                },
                                                "& .MuiSelect-icon": {
                                                    color: theme.palette.customHeaderButton.main
                                                }   
                                                ,
                                            }}
                                            labelId="station-label"
                                            input={<OutlinedInput color='customHeaderButton' label="Station" />}
                                            onChange={selectStation}
                                            autoWidth={true}
                                            value={stationStore.id ?? ""}

                                            color="customHeaderButton"
                                            size='small'
                                        >
                                            
                                            {stations.map((item) => {
                                                
                                                return <MenuItem key={item.id!} value={item.id!}>{item.id!}</MenuItem>
                                            })}

                                        </Select>
                                    </FormControl>
                                )

                            ): null
                        ): null}
                        
                    </Grid>
                    <Grid item 
                        xs={2} 
                        // sm={3} 
                        lg={2}
                    >
                        {!isInstructionsPage ? (
                            !isHomePage ? (
                                !isCustomerDataPage ?(
                                    !isFulfillerDataPage ? (
                                        !isWorkplacesPage ? (
                                            !isAccountPage && (
                                                <Button color='customHeaderButton' variant={buttonType} onClick={gotoCorrectPage}  style={{height:'5vh',border: '2px solid', minWidth:"0px"}} fullWidth>
                                                    <Typography noWrap sx={{display: { xs: 'none', md: 'block' }}}>
                                                        {t("next")}
                                                    </Typography>
                                                    <ChevronRight sx={{display: { xs: 'block', md: 'none' }}} />
                                                </Button>
                                            )
                                        ):null
                                    ):null
                                ):null
                            ):null
                        ):null}
                    </Grid>
                    
                </Grid>

                {globalJobId && globalJobId !== -1 &&
                    <CameraComponent onSave={saveImageToJob} />
                }

                <ConnectionStatus />

                {hasLicense("shutdown") && 

                    <PowerOffButton />
                }
            </Grid>

            <Dialog
                open={openLogoutDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t("resetTarget")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("resetQuestion")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>
                        {t("no")}
                    </Button>
                    <Button onClick={handleConfirmLogout} autoFocus>
                        {t("yes")}
                    </Button>
                </DialogActions>
            </Dialog>
        </AppBar>
        
        //</ThemeProvider>
    )
}

export default Topbar;