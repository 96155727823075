import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { CameraAlt } from "@mui/icons-material";
import { Badge, Button } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useImagesStore, useSettingsStore } from "../../store/store";


interface ICameraComponentProps {
    onSave?: (base64Image:string) => void
}

const CameraComponent = (props:ICameraComponentProps) => {

    const [imageUrl, setImageUrl] = useState<string>();
    
    const takePicture = async () => {

        const perm = await Camera.checkPermissions();


        // const req = await Camera.requestPermissions({permissions:["camera"]});

        try {
            const image = await Camera.getPhoto({
              quality: 90,
              allowEditing: true,
            //   resultType: CameraResultType.Uri,
              resultType: CameraResultType.Base64,
              source: CameraSource.Camera,
            //   webUseInput: true
    
            });
            
            // image.webPath will contain a path that can be set as an image src.
            // You can access the original file using image.path, which can be
            // passed to the Filesystem API to read the raw data of the image,
            // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
            // let imageUrl = image.webPath;
            let base64Image = image.base64String;
          
            // Can be set to the src of an image now
            // imageElement.src = imageUrl;
    
            // setImageUrl(imageUrl);

            if(base64Image){
                saveImage(base64Image);
            }


        } catch (error) {
            console.log(error);
            
        }
    };


    const saveImage = (base64Image:string) => {

        if(props.onSave){
            props.onSave(base64Image);
        }
    }

    const globalJobId = useSettingsStore(state => state.jobId);
    
    const imagesString = useImagesStore(state => state.imagesString);
    const setImagesString = useImagesStore(state => state.setImagesString);

    const imgCount = useMemo(() => {
        let count = 0;

        if(imagesString && imagesString.length > 0){
            try {
                const data = JSON.parse(imagesString);
        
                if(data[globalJobId]){
                    const images:string[] = data[globalJobId];
                    count = images.length;
                }
                        
            } catch (error) {
                // throw error;
            }

        }



        return count;
    }, [globalJobId, imagesString]);

    // const renderImage = () => {
        
    //     if(!imageUrl){
    //         return (
    //             <div>no image</div>
    //         );
    //     }
        
    //     return (
    //         <img src={imageUrl} />
    //     );
    // }
    
    return (
        <>
        
            <Button 
                // sx={{color: theme.palette.customHeaderButton.main}} 
                color='customHeaderButton' 
                // variant={"outlined"}
                // style={{height:'5vh',border: '2px solid', minWidth:"0px"}} 
                // fullWidth

                onClick={() => {
                    takePicture();
                }}
            >
                <Badge badgeContent={imgCount} color="secondary">
                    <CameraAlt />
                </Badge>
            </Button>
                

            {/* {renderImage()} */}

        </>
    )
};

export default CameraComponent;
