import React, { useEffect, useMemo, useState } from "react";
import { useImagesStore, useSettingsStore } from "../../store/store";
import { Button, Card, Grid } from "@mui/material";
import { Delete } from "@mui/icons-material";

interface IImageListProps {

}

const ImageList = (props:IImageListProps) => {

    const globalJobId = useSettingsStore(state => state.jobId);
    
    const imagesString = useImagesStore(state => state.imagesString);
    const setImagesString = useImagesStore(state => state.setImagesString);
    
    // const imagesString = localStorage.getItem("images");

    // const images: string[] = useMemo(() => {
    //     if(imagesString && imagesString.length > 0){
    //         const data = JSON.parse(imagesString);

    //         if(data[globalJobId]){
    //             return data[globalJobId];
    //         }
    //     }

    //     return [];
    // }, [imagesString, globalJobId]);


    
    const [images, setImages] = useState<string[]>([]);

    useEffect(() => {
        if(imagesString && imagesString.length > 0){
            const data = JSON.parse(imagesString);

            if(data[globalJobId]){
                setImages(data[globalJobId]);
            }
        }
    }, [globalJobId, imagesString])


    const deleteImg = (index:number) => {
        const newImages = [...images];

        newImages.splice(index, 1);

        if(imagesString && imagesString.length > 0){
            const data = JSON.parse(imagesString);

            if(data[globalJobId]){
                data[globalJobId] = newImages;

                // localStorage.setItem("images", JSON.stringify(data));
                setImagesString(JSON.stringify(data));
            }
        }

        setImages(newImages);
    }

    const renderImage = (base64Image:string, index:number) => {
        
        if(!base64Image){
            return (
                <div>no image</div>
            );
        }
        
        return (
            <Grid item xs={12/2} sm={12/3} md={12/5} lg={12/10}>
                <Card>
                    <img src={`data:image/jpeg;base64,${base64Image}`} alt={`img ${index}`} style={{maxHeight:"100%", maxWidth:"100%"}}/>
                    <Button className="absolute top-0 right-0 w-[16px] h-[16px] bg-white rounded-md" onClick={() => {
                        deleteImg(index);
                    }}>
                        <Delete />
                    </Button>
                </Card>
            </Grid>
        );
    }

    return (
        <Grid container spacing={2} columns={12}>
            {images &&
            
                images.map((image, index) => {
                    return renderImage(image, index);
                })
            }
        </Grid>
    )
};

export default ImageList;
