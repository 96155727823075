/* tslint:disable */
/* eslint-disable */
/**
 * Workplace
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Device,
  Workplace,
} from '../models';

export interface DeleteDeviceRequest {
    id: string;
}

export interface GetWorkplacesByDeviceRequest {
    id: string;
}

export interface PostDeviceRequest {
    device?: Device;
}

/**
 * 
 */
export class DeviceApi extends runtime.BaseAPI {

    /**
     * Löscht ein Device
     */
    async deleteDeviceRaw(requestParameters: DeleteDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/device/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Löscht ein Device
     */
    async deleteDevice(requestParameters: DeleteDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDeviceRaw(requestParameters, initOverrides);
    }

    /**
     * Listet alle Devices auf 
     */
    async getAllDevicesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Device>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/devices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Listet alle Devices auf 
     */
    async getAllDevices(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Device>> {
        const response = await this.getAllDevicesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Liefert den Workplace für ein Device 
     */
    async getWorkplacesByDeviceRaw(requestParameters: GetWorkplacesByDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Workplace>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getWorkplacesByDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/device/{id}/workplace`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Liefert den Workplace für ein Device 
     */
    async getWorkplacesByDevice(requestParameters: GetWorkplacesByDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Workplace> {
        const response = await this.getWorkplacesByDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Legt ein neues Device an 
     */
    async postDeviceRaw(requestParameters: PostDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Device>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/device`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.device,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Legt ein neues Device an 
     */
    async postDevice(requestParameters: PostDeviceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Device> {
        const response = await this.postDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
