import {Box, Button, Card, Grid, TextField, Typography} from '@mui/material';
import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {JobApi} from "../generated/job";
import {PDFApi, SuperJob, Address, SuperJobAdditionalInfoInner} from "../generated/pdf";
import {
     Einstellwerte,

} from "../generated/car";
import { useImagesStore, useSettingsStore, useSuperJobStore, useThemeStore, useValueStore } from '../store/store';
import { useTranslation } from 'react-i18next';
import ImageList from '../components/camera/imageList';
import { useSnackbar } from '../utils/snackbar';

const Customerdata = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const showSnackbar = useSnackbar();
    

    const themeName = useThemeStore(state => state.themeName);
    
    const superJob = useSuperJobStore(state => state.superJob);
    const resetSuperJob = useSuperJobStore(state => state.resetSuperJob);

    const globalMessTyp = useValueStore(state => state.messtyp);
    // const setGlobalMessTyp = useValueStore(state => state.updateMesstyp);

    
    const globalHIST = useSettingsStore(state => state.hist);
    const globalLIST = useSettingsStore(state => state.list);
    const globalRIST = useSettingsStore(state => state.rist);
    
    const globalAlignment = useSettingsStore(state => state.alignmentType);
    const globalIsMultiPhaseCalibration = useSettingsStore(state => state.isMultiPhaseCalibration);

    const globalJobId = useSettingsStore(state => state.jobId);
    const setGlobalJobId = useSettingsStore(state => state.setJobId);
    
    const globalLIST_Phase2 = useSettingsStore(state => state.list_phase2);
    const globalRIST_Phase2 = useSettingsStore(state => state.rist_phase2);

    
    const imagesString = useImagesStore(state => state.imagesString);
    const setImagesString = useImagesStore(state => state.setImagesString);


    const jobApi: JobApi = new JobApi();
    const pdfApi: PDFApi = new PDFApi();
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0]; // Formatieren des Datums als "YYYY-MM-DD"
    const [customer, setCustomer] = useState({
        kundenname: '',
        kennzeichen: '',
        vinNummer: '',
        bemerkung: '',
    });

    const updateField = (e: any) => {
        setCustomer({
            ...customer,
            [e.target.name]: e.target.value,
        });
    };

    const getImages = () => {
        if(imagesString && imagesString.length > 0){
            try {
                const data = JSON.parse(imagesString);
        
                if(data[globalJobId]){
                    let jobApi = new JobApi();

                    const images:string[] = data[globalJobId];
                    
                    return images;
                }
                        
            } catch (error) {
                // throw error;
            }

        }

        return [];
    }

    const saveImages = async() => {
        // const imagesString = localStorage.getItem("images");

        const images = getImages();

        if(images){
            for(const image in images){
                try {
                    await jobApi.postImage({ "id": globalJobId, postImageRequest:{"image": image}});
                } catch (error) {
                    console.log("img api not found");
                                        
                }
            }
        }
    }

    
    const resetImages = (id:number) => {
        const imagesString = localStorage.getItem("images");
        
        if(imagesString && imagesString.length > 0){
            try {
                const data = JSON.parse(imagesString);
        
                if(data[globalJobId]){
                   delete data[globalJobId];
                }

                // localStorage.setItem("images", JSON.stringify(data));
                setImagesString(JSON.stringify(data));

                        
            } catch (error) {
                
            }

        }

    }

    const finish = async () => {
        console.log(customer);

        let sj: SuperJob = superJob;

        let sjInfoInner : SuperJobAdditionalInfoInner;
        sj.hist = globalHIST ? globalHIST.toString() : '';
        sj.list = globalLIST ? globalLIST.toString() : '';
        sj.rist = globalRIST ? globalRIST.toString() : '';
        sj.listphase2 = globalLIST_Phase2 ? globalLIST_Phase2.toString() : '';
        sj.ristphase2 = globalRIST_Phase2 ? globalRIST_Phase2.toString() : '';

        const monteurFromStorage = localStorage.getItem("monteur");
        let monteur:Address|undefined;
    
        if(monteurFromStorage){
            try {
                monteur = JSON.parse(monteurFromStorage)
            } catch (error) {
                
            }
        }
        sj.fulfiller = monteur;

        sj.theme = themeName;
        
        sj.step = globalMessTyp;

        sj.alignmentType = globalAlignment;
        sj.themeName = themeName;
        sj.mehrphasenkalibrierung = globalIsMultiPhaseCalibration.toString();

        
        let myew: Einstellwerte = {};
        try {
            const json = localStorage.getItem("einstellwert");
            if(json){
                myew = JSON.parse(json);

            }
        } catch (error) {
            
        }


        let jsonWerte: any;
        if(myew.werte != null && myew.werte.length > 0) {
            jsonWerte = JSON.parse(myew.werte);
        }
        if(jsonWerte != null) {
            sj.hsoll = jsonWerte['Höhe_Kalibriertafel'];
            sj.lsoll = jsonWerte['Abstand_Kalibriertafel'];
            sj.rsoll = jsonWerte['Abstand_Kalibriertafel'];
            sj.lsollphase2 = jsonWerte['Abstand_Kalibriertafel_Phase2'];
            sj.rsollphase2 = jsonWerte['Abstand_Kalibriertafel_Phase2'];
        }
        if(myew.kalibriertafelID != null) {
            sj.target = myew.kalibriertafelID.toString();
        }

        let cust: Address = {
            "firstName": customer.kundenname,
            "lastName": '',
            "city": '',
            "postcode": '',
            "country": '',
            "email": ''
        };
       // sj.customer = new class implements Address {}
        sj.customer = cust;
        sj.kennzeichen = customer.kennzeichen;
        sj.vin = customer.vinNummer;
        sjInfoInner = {value: customer.bemerkung, name: "bemerkung"}
        sj.additionalInfo = [sjInfoInner];
        
        
        console.log("Hist" + sj.hist);
        console.log("list" + sj.list);
        console.log("rist" + sj.rist);

        console.log("listphase2" + sj.listphase2);
        console.log("ristphase2" + sj.ristphase2);
        console.log("fulfiller" + sj.fulfiller);

        console.log("theme" + sj.theme);
        console.log("fahrzeug" + sj.fahrzeug);
        console.log("step" + sj.step);

        console.log("theme" + sj.alignmentType);
        console.log("fahrzeug" + sj.themeName);
        console.log("step" + sj.mehrphasenkalibrierung);

        const images = getImages();

        if(images){
            const imageList = images.map((img) => {
                return {
                    image: img
                }
            })
            sj.images = imageList;
        }
        
        // return

        try {
            const data =  await jobApi.getJob({"id": globalJobId});
    
            if(data){
                await saveImages();
    
                const blob = await pdfApi.postPdf(
                    {
                        "superJob": sj
                    }
                );
    
                if(blob){
                    console.log(blob);
                    console.log("Hist" + sj.hist);
                    console.log("list" + sj.list);
                    console.log("rist" + sj.rist);
    
                    console.log("listphase2" + sj.listphase2);
                    console.log("ristphase2" + sj.ristphase2);
                    console.log("fulfiller" + sj.fulfiller);
    
                    console.log("theme" + sj.theme);
                    console.log("fahrzeug" + sj.fahrzeug);
                    console.log("step" + sj.step);
    
                    console.log("theme" + sj.alignmentType);
                    console.log("fahrzeug" + sj.themeName);
                    console.log("step" + sj.mehrphasenkalibrierung);
    
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
    
                    let tmpFileName = t("pdfFilename");
    
                    if(tmpFileName.trim().length === 0){
                        // default
                        tmpFileName = "Ausrichtungsprotokoll";
                    }
    
                    const pdfFileName = `${tmpFileName}_${sj.vin}_${sj.kennzeichen}_${formattedDate}.pdf`; // Setze hier den gewünschten Dateinamen ein
                    link.setAttribute('download', pdfFileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
    
            }

            if(globalJobId !== undefined && globalJobId !== -1) {
                jobApi.changeState({
                    "id": globalJobId,
                    "state": "Completed",
                })
    
                resetImages(globalJobId);
            }
    
    
            setGlobalJobId(-1);
    
            // SuperJob abloeschen
            resetSuperJob();
    
            navigate("/");
            
        } catch (error) {
            showSnackbar(t("saveError"))
        }


        // jobApi.getJob({"id": globalJobId}).then(data => {
        //     pdfApi.postPdf(
        //         {
        //             "superJob": sj
        //         }
        //     ).then(blob => {
        //         console.log(blob);
        //         console.log("Hist" + sj.hist);
        //         console.log("list" + sj.list);
        //         console.log("rist" + sj.rist);

        //         console.log("listphase2" + sj.listphase2);
        //         console.log("ristphase2" + sj.ristphase2);
        //         console.log("fulfiller" + sj.fulfiller);

        //         console.log("theme" + sj.theme);
        //         console.log("fahrzeug" + sj.fahrzeug);
        //         console.log("step" + sj.step);

        //         console.log("theme" + sj.alignmentType);
        //         console.log("fahrzeug" + sj.themeName);
        //         console.log("step" + sj.mehrphasenkalibrierung);

        //         const url = window.URL.createObjectURL(blob);
        //         const link = document.createElement('a');
        //         link.href = url;

        //         let tmpFileName = t("pdfFilename");

        //         if(tmpFileName.trim().length === 0){
        //             // default
        //             tmpFileName = "Ausrichtungsprotokoll";
        //         }

        //         const pdfFileName = `${tmpFileName}_${sj.vin}_${sj.kennzeichen}_${formattedDate}.pdf`; // Setze hier den gewünschten Dateinamen ein
        //         link.setAttribute('download', pdfFileName);
        //         document.body.appendChild(link);
        //         link.click();
        //         document.body.removeChild(link);
        //     });
        // });



        
    }

    return (
        <Grid container alignItems={'center'} justifyContent={'center'} textAlign={'center'} spacing={0} sx={{ backgroundColor: 'lightgrey' }}>
            <Grid  item xs={12}  justifyContent={'center'} alignItems={'center'}>

                <Card 
                    // style={{width: '99%'}}
                    sx={{ padding: { xs: '10px', md: '16px' } }}

                >
                    <Grid container alignItems={'center'} justifyContent={'center'} textAlign={'center'} spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{ textAlign: 'center', height: '8vh' }}>
                                <Typography variant='h4' mt={2}>{t("customerData")}</Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} spacing={2} alignItems={"left"} justifyContent={"left"}>
                        <Grid item xs={12}>
                            <Box>
                                <TextField 
                                    name={"kundenname"} 
                                    value={customer.kundenname}
                                    onChange={updateField}
                                    
                                    label={t("customerName")}
                                    variant="outlined" 
                                    
                                    fullWidth

                                    // sx={{ backgroundColor: 'white' }}
                                />
                            </Box>
                        </Grid>


                        
                        <Grid item xs={12}>
                            <TextField 
                                name="kennzeichen" 
                                value={customer.kennzeichen} 
                                onChange={updateField}

                                label={t("licensePlate")}
                                variant='outlined'

                                fullWidth

                                // sx={{ backgroundColor: 'white' }} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                name="vinNummer" 
                                value={customer.vinNummer} 
                                onChange={updateField}

                                label={t("vinNumber")}
                                variant='outlined'

                                fullWidth

                                // sx={{ backgroundColor: 'white' }} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                name="bemerkung" 
                                value={customer.bemerkung} 
                                onChange={updateField}

                                label={t("comment")}
                                variant='outlined'

                                fullWidth

                                // sx={{ backgroundColor: 'white' }} 
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <ImageList />
                        </Grid>

                        <Box sx={{ height: '50px' }}></Box>
                            
                        <Grid item xs={12}>
                            <Button 
                                variant={"contained"} 
                                onClick={finish} 
                                sx={{padding: '15px'}} 
                                style={{width:300, maxWidth:"100%"}}
                            >
                                {t("createPdf")}
                            </Button>
                        </Grid>
                        
                    

                        <Box sx={{ height: '50px' }}></Box>

                    </Grid>

                </Card>
            </Grid>
        </Grid>
    );
}

export default Customerdata;