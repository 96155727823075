import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, useTheme } from "@mui/material";
import i18next from "i18next";
import { useAuth } from "oidc-react";
import React, { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

import Flag from "react-flagpack";

interface ILanguageSelectionProps {
    onSelected?: () => void;
}

const languages = ["de", "en"];

export const determineFlag = (input?:string) => {
    if (!input ||input.length === 0)
    {
        return undefined
    }

    if (input==='UK' || input=== 'GB' || input=== 'EN')
    {
        return "GB-UKM"
    }

    return input
}

const LanguageSelection = (props:ILanguageSelectionProps) => {


    // const auth = useAuth();
    const theme = useTheme();
    const { t } = useTranslation();

    const language = i18n.language;

    // const languages = i18next.languages;

    // const languages = ["de", "en"];

    const languagesOptions = useMemo(() => {
        const languagesOptions:{
            value:string,
            label: string,
            icon?: ReactNode
        }[] = [];
        
        for (let loc of Array.from(languages.values())) {
    
            const langTranslatedCurrent = t(`common:languages.${loc.toLowerCase()}`)
            // const langTranslated = t(`languageselectionDE:languages.${loc.toLowerCase()}.name`)
            const langTranslated = t(`languagesOriginal.${loc.toLowerCase()}.name`)
            
            // const intro = t(`languageselectionDE:languages.${loc.toLowerCase()}.intro`)

            const flagCode = determineFlag(loc.toUpperCase());

            languagesOptions.push({
                label: `${langTranslated} (${langTranslatedCurrent})`,
                value: loc,
                icon: flagCode ? <Flag size="s" code={flagCode} gradient="real-linear" hasDropShadow={false} hasBorder={false} className="inline-block"/> : null

            })
          
        }

        return languagesOptions;
    }, [t])

    const selectLanguage = (event: SelectChangeEvent) => {
        // setStation(event.target.value);
        // console.log("selectLanguage", event.target.value);
        
        i18next.changeLanguage(event.target.value);

        if(props.onSelected){
            props.onSelected();
        }
            
    }

    return (


            <FormControl 
                sx={{ 
                    m:1, 
                    color: theme.palette.primary.main
                    // border: '2px solid #ccc'
                }} 
                fullWidth
            >
                <InputLabel 
                    sx={{
                        color: theme.palette.primary.main
                    }} 
                    id="language-label"
                >
                    {t("language")}
                </InputLabel>
                <Select
                    sx={{
                        color: theme.palette.primary.main,
                        "& fieldset": {
                            border: '2px solid',
                            borderColor: theme.palette.primary.main

                        },
                        "& .MuiSelect-icon": {
                            color: theme.palette.primary.main
                        }   
                        ,
                    }}
                    labelId="language-label"
                    input={<OutlinedInput color='primary' label={t("language")} />}
                    onChange={selectLanguage}
                    autoWidth={true}
                    value={language}

                    color="primary"
                >
                    
                    {languagesOptions.map((item) => {
                        
                        return <MenuItem key={item.value!} value={item.value!}>
                            <div style={{display:"flex", gap:"8px"}}>
                                {item.icon && 
                                    <span className="me-2">
                                        {item.icon}
                                    </span>
                                }
                                <span>
                                    {item.label!}
                                </span>
                            </div>
                        </MenuItem>
                    })}

                </Select>
            </FormControl>
        
    )
};

export default LanguageSelection;
