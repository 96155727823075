/* tslint:disable */
/* eslint-disable */
/**
 * PDF
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SuperJob,
} from '../models';

export interface PostPdfRequest {
    superJob?: SuperJob;
}

/**
 * 
 */
export class PDFApi extends runtime.BaseAPI {

    /**
     * Listet alle PDFs für den Aktiven Nutzer auf (Für ein Device maximal 1 und für einen User alle vorhandenen) 
     */
    async getPdfsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SuperJob>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Listet alle PDFs für den Aktiven Nutzer auf (Für ein Device maximal 1 und für einen User alle vorhandenen) 
     */
    async getPdfs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SuperJob>> {
        const response = await this.getPdfsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Startet eine neue Target Ausrichtung. Sollte für das übergebene Fahrzeug keine Daten vorhanden sein oder die Targets nicht im aktuellen Abo enthalten, wird mit den entsprechenden  HTTP-Codes geantwortet. 
     */
    async postPdfRaw(requestParameters: PostPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.superJob,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Startet eine neue Target Ausrichtung. Sollte für das übergebene Fahrzeug keine Daten vorhanden sein oder die Targets nicht im aktuellen Abo enthalten, wird mit den entsprechenden  HTTP-Codes geantwortet. 
     */
    async postPdf(requestParameters: PostPdfRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.postPdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
