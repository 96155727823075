import React, { createContext, useState, useContext, ReactNode } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor } from '@mui/material/Alert';

const SnackbarContext = createContext((message: string, severity?: AlertColor, duration?: number) => {});

export const useSnackbar = () => {
    return useContext(SnackbarContext);
};

interface ISnackBarState { 
    open: boolean, 
    message: string, 
    severity: AlertColor,
    duration: number
}
const DEFAULT_DURATION = 6000;

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
    const [snackbar, setSnackbar] = useState<ISnackBarState>({ open: false, message: '', severity: 'success', duration: DEFAULT_DURATION });

    const showSnackbar = (message: string, severity:AlertColor = 'success', duration:number = DEFAULT_DURATION) => {
        setSnackbar({ open: true, message, severity, duration });
    };

    const handleClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <SnackbarContext.Provider value={showSnackbar}>
            {children}
            <Snackbar
                className='mt-safe'
                open={snackbar.open}
                autoHideDuration={snackbar.duration}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <MuiAlert onClose={handleClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </MuiAlert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
};
