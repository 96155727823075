import { PowerSettingsNew } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from "@mui/material";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Socket } from "socket.io-client";
import { useConfigStore, useStationStore } from "../store/store";
import { getSocket } from "../utils/socket";
import { useShallow } from "zustand/react/shallow";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "../utils/snackbar";

interface IPowerOffButtonProps {

}

const PowerOffButton = (props:IPowerOffButtonProps) => {
    const theme = useTheme();

    const { t } = useTranslation();

    const showSnackbar = useSnackbar();

    const [shutdownDialogOpen, setShutdownDialogOpen] = useState(false);

    const stationStore = useStationStore(useShallow(state => state.station));

    const stationId = stationStore?.id?.toLowerCase();

    const hasLicense = useConfigStore(state => state.hasLicense);

    // const device = useRef<any>(null);
   
    const socketRef = useRef<Socket>();
    
    const stationDevices = useMemo(() => {
        return stationStore.devices;
    }, [JSON.stringify(stationStore.devices)]);


    // const connect = (deviceParam: any) => {
    //     const socket = socketRef.current;

    //     if (!device.current || deviceParam.id !== device.current.id) {
    //         // disconnect();
    //         disconnectCallback();
    //         device.current = deviceParam;
    //     }

    //     if (socket?.connected) {
    //         console.log(`emit connect to ${device.current.id!.toLowerCase()}`);
    //         socket.emit("con", device.current.id!.toLowerCase(), '{"pos":' + 'dummy' + '}');
    //     }

    // };


    // const disconnect = () => {
    //     const socket = socketRef.current;

    //     if (!socket) {
    //         return;
    //     }
    //     if (!device.current) {
    //         return
    //     }

    //     const current = device.current;

    //     device.current = null;

    //     console.log(`emit disconnect to ${current.id!.toLowerCase()}`, '{"pos":' + 'dummy' + '}');
    //     socket.emit("dis", current.id!.toLowerCase(), '{"pos":' + 'dummy' + '}');
    // };


    // const disconnectCallback = useCallback(disconnect, [])
    // const connectCallback = useCallback(connect, [disconnectCallback])






    const shutDown = () => {

        if(!socketRef.current){
            socketRef.current = getSocket(`https://data.data2work.com/${stationId}`);
        }
        
        if(stationDevices){
            for (const dev of stationDevices) {
                if(dev.type === "Gateway"){
    
                    socketRef.current.emit("power", dev.id!, `{"shutDown": true }`)
    
                    break;
                }
            }
        }

        showSnackbar(t("shutDownInProgress"), "info", 10000);

        handleCloseDialog();
    }
    
    const handleShutDownPopup = () => {
        setShutdownDialogOpen(true);
    }

    const handleCloseDialog = () => {
        setShutdownDialogOpen(false);
    }

    if(!hasLicense("shutdown")){
        return null;
    }

    return (
        <>
            <Button 
                sx={{color: theme.palette.customHeaderButton.main}} 
                onClick={handleShutDownPopup} 
                // style={{color: 'white'}}
            >
                <PowerSettingsNew />
            </Button>

            <Dialog
                open={shutdownDialogOpen}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{t("shutDown")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("shutDownQuestion")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>{t("no")}</Button>
                    <Button onClick={shutDown} autoFocus>
                        {t("yes")}
                    </Button>
                </DialogActions>
            </Dialog>
        
        </>
    )
};

export default PowerOffButton;
